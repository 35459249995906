/* Margin */
.m-0 { margin: 0 !important; }
.m-1 { margin: 0.25rem !important; }
.m-2 { margin: 0.5rem !important; }
.m-3 { margin: 0.75rem !important; }
.m-4 { margin: 1rem !important; }
.m-5 { margin: 1.25rem !important; }
.m-6 { margin: 1.5rem !important; }
.m-7 { margin: 1.75rem !important; }
.m-8 { margin: 2rem !important; }

/* Margin - Auto */
.m-auto { margin: auto !important; }

/* Margin - Top */
.mt-0 { margin-top: 0 !important; }
.mt-1 { margin-top: 0.25rem !important; }
.mt-2 { margin-top: 0.5rem !important; }
.mt-3 { margin-top: 0.75rem !important; }
.mt-4 { margin-top: 1rem !important; }
.mt-5 { margin-top: 1.25rem !important; }
.mt-6 { margin-top: 1.5rem !important; }
.mt-7 { margin-top: 1.75rem !important; }
.mt-8 { margin-top: 2rem !important; }
.mt-auto { margin-top: auto !important; }

/* Margin - Bottom */
.mb-0 { margin-bottom: 0 !important; }
.mb-1 { margin-bottom: 0.25rem !important; }
.mb-2 { margin-bottom: 0.5rem !important; }
.mb-3 { margin-bottom: 0.75rem !important; }
.mb-4 { margin-bottom: 1rem !important; }
.mb-5 { margin-bottom: 1.25rem !important; }
.mb-6 { margin-bottom: 1.5rem !important; }
.mb-7 { margin-bottom: 1.75rem !important; }
.mb-8 { margin-bottom: 2rem !important; }
.mb-auto { margin-bottom: auto !important; }

/* Margin - Left */
.ml-0 { margin-left: 0 !important; }
.ml-1 { margin-left: 0.25rem !important; }
.ml-2 { margin-left: 0.5rem !important; }
.ml-3 { margin-left: 0.75rem !important; }
.ml-4 { margin-left: 1rem !important; }
.ml-5 { margin-left: 1.25rem !important; }
.ml-6 { margin-left: 1.5rem !important; }
.ml-7 { margin-left: 1.75rem !important; }
.ml-8 { margin-left: 2rem !important; }
.ml-auto { margin-left: auto !important; }

/* Margin - Right */
.mr-0 { margin-right: 0 !important; }
.mr-1 { margin-right: 0.25rem !important; }
.mr-2 { margin-right: 0.5rem !important; }
.mr-3 { margin-right: 0.75rem !important; }
.mr-4 { margin-right: 1rem !important; }
.mr-5 { margin-right: 1.25rem !important; }
.mr-6 { margin-right: 1.5rem !important; }
.mr-7 { margin-right: 1.75rem !important; }
.mr-8 { margin-right: 2rem !important; }
.mr-auto { margin-right: auto !important; }

/* Margin - X (Left & Right) */
.mx-0 { margin-left: 0 !important; margin-right: 0 !important; }
.mx-1 { margin-left: 0.25rem !important; margin-right: 0.25rem !important; }
.mx-2 { margin-left: 0.5rem !important; margin-right: 0.5rem !important; }
.mx-3 { margin-left: 0.75rem !important; margin-right: 0.75rem !important; }
.mx-4 { margin-left: 1rem !important; margin-right: 1rem !important; }
.mx-5 { margin-left: 1.25rem !important; margin-right: 1.25rem !important; }
.mx-6 { margin-left: 1.5rem !important; margin-right: 1.5rem !important; }
.mx-7 { margin-left: 1.75rem !important; margin-right: 1.75rem !important; }
.mx-8 { margin-left: 2rem !important; margin-right: 2rem !important; }
.mx-auto { margin-left: auto !important; margin-right: auto !important; }

/* Margin - Y (Top & Bottom) */
.my-0 { margin-top: 0 !important; margin-bottom: 0 !important; }
.my-1 { margin-top: 0.25rem !important; margin-bottom: 0.25rem !important; }
.my-2 { margin-top: 0.5rem !important; margin-bottom: 0.5rem !important; }
.my-3 { margin-top: 0.75rem !important; margin-bottom: 0.75rem !important; }
.my-4 { margin-top: 1rem !important; margin-bottom: 1rem !important; }
.my-5 { margin-top: 1.25rem !important; margin-bottom: 1.25rem !important; }
.my-6 { margin-top: 1.5rem !important; margin-bottom: 1.5rem !important; }
.my-7 { margin-top: 1.75rem !important; margin-bottom: 1.75rem !important; }
.my-8 { margin-top: 2rem !important; margin-bottom: 2rem !important; }
.my-auto { margin-top: auto !important; margin-bottom: auto !important; }

/* Padding */
.p-0 { padding: 0 !important; }
.p-1 { padding: 0.25rem !important; }
.p-2 { padding: 0.5rem !important; }
.p-3 { padding: 0.75rem !important; }
.p-4 { padding: 1rem !important; }
.p-5 { padding: 1.25rem !important; }
.p-6 { padding: 1.5rem !important; }
.p-7 { padding: 1.75rem !important; }
.p-8 { padding: 2rem !important; }

/* Padding - Auto */
.p-auto { padding: auto !important; }

/* Padding - Top */
.pt-0 { padding-top: 0 !important; }
.pt-1 { padding-top: 0.25rem !important; }
.pt-2 { padding-top: 0.5rem !important; }
.pt-3 { padding-top: 0.75rem !important; }
.pt-4 { padding-top: 1rem !important; }
.pt-5 { padding-top: 1.25rem !important; }
.pt-6 { padding-top: 1.5rem !important; }
.pt-7 { padding-top: 1.75rem !important; }
.pt-8 { padding-top: 2rem !important; }
.pt-auto { padding-top: auto !important; }

/* Padding - Bottom */
.pb-0 { padding-bottom: 0 !important; }
.pb-1 { padding-bottom: 0.25rem !important; }
.pb-2 { padding-bottom: 0.5rem !important; }
.pb-3 { padding-bottom: 0.75rem !important; }
.pb-4 { padding-bottom: 1rem !important; }
.pb-5 { padding-bottom: 1.25rem !important; }
.pb-6 { padding-bottom: 1.5rem !important; }
.pb-7 { padding-bottom: 1.75rem !important; }
.pb-8 { padding-bottom: 2rem !important; }
.pb-auto { padding-bottom: auto !important; }

/* Padding - Left */
.pl-0 { padding-left: 0 !important; }
.pl-1 { padding-left: 0.25rem !important; }
.pl-2 { padding-left: 0.5rem !important; }
.pl-3 { padding-left: 0.75rem !important; }
.pl-4 { padding-left: 1rem !important; }
.pl-5 { padding-left: 1.25rem !important; }
.pl-6 { padding-left: 1.5rem !important; }
.pl-7 { padding-left: 1.75rem !important; }
.pl-8 { padding-left: 2rem !important; }
.pl-auto { padding-left: auto !important; }

/* Padding - Right */
.pr-0 { padding-right: 0 !important; }
.pr-1 { padding-right: 0.25rem !important; }
.pr-2 { padding-right: 0.5rem !important; }
.pr-3 { padding-right: 0.75rem !important; }
.pr-4 { padding-right: 1rem !important; }
.pr-5 { padding-right: 1.25rem !important; }
.pr-6 { padding-right: 1.5rem !important; }
.pr-7 { padding-right: 1.75rem !important; }
.pr-8 { padding-right: 2rem !important; }
.pr-auto { padding-right: auto !important; }

/* Padding - X (Left & Right) */
.px-0 { padding-left: 0 !important; padding-right: 0 !important; }
.px-1 { padding-left: 0.25rem !important; padding-right: 0.25rem !important; }
.px-2 { padding-left: 0.5rem !important; padding-right: 0.5rem !important; }
.px-3 { padding-left: 0.75rem !important; padding-right: 0.75rem !important; }
.px-4 { padding-left: 1rem !important; padding-right: 1rem !important; }
.px-5 { padding-left: 1.25rem !important; padding-right: 1.25rem !important; }
.px-6 { padding-left: 1.5rem !important; padding-right: 1.5rem !important; }
.px-7 { padding-left: 1.75rem !important; padding-right: 1.75rem !important; }
.px-8 { padding-left: 2rem !important; padding-right: 2rem !important; }
.px-auto { padding-left: auto !important; padding-right: auto !important; }

/* Padding - Y (Top & Bottom) */
.py-0 { padding-top: 0 !important; padding-bottom: 0 !important; }
.py-1 { padding-top: 0.25rem !important; padding-bottom: 0.25rem !important; }
.py-2 { padding-top: 0.5rem !important; padding-bottom: 0.5rem !important; }
.py-3 { padding-top: 0.75rem !important; padding-bottom: 0.75rem !important; }
.py-4 { padding-top: 1rem !important; padding-bottom: 1rem !important; }
.py-5 { padding-top: 1.25rem !important; padding-bottom: 1.25rem !important; }
.py-6 { padding-top: 1.5rem !important; padding-bottom: 1.5rem !important; }
.py-7 { padding-top: 1.75rem !important; padding-bottom: 1.75rem !important; }
.py-8 { padding-top: 2rem !important; padding-bottom: 2rem !important; }
.py-auto { padding-top: auto !important; padding-bottom: auto !important; }

/* Flexbox Layout */
.d-flex {
    display: flex;
}

.d-inline-flex {
    display: inline-flex;
}

.flex-row {
    flex-direction: row;
}

.flex-column {
    flex-direction: column;
}

.justify-start {
    justify-content: flex-start;
}

.justify-center {
    justify-content: center;
}

.justify-end {
    justify-content: flex-end;
}

.align-start {
    align-items: flex-start;
}

.align-center {
    align-items: center;
}

.align-end {
    align-items: flex-end;
}

/* Grid system */
/* .container {
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    padding-right: 15px;
    padding-left: 15px;
} */

.row {
    display: flex;
    flex-wrap: wrap;
}

.col {
    flex: 1;
    padding: 15px;
}

.col-1 { flex: 0 0 8.33%; }
.col-2 { flex: 0 0 16.66%; }
.col-3 { flex: 0 0 25%; }
.col-4 { flex: 0 0 33.33%; }
.col-5 { flex: 0 0 41.66%; }
.col-6 { flex: 0 0 50%; }
.col-7 { flex: 0 0 58.33%; }
.col-8 { flex: 0 0 66.66%; }
.col-9 { flex: 0 0 75%; }
.col-10 { flex: 0 0 83.33%; }
.col-11 { flex: 0 0 91.66%; }
.col-12 { flex: 0 0 100%; }

/* Text Alignment */
.text-left {
    text-align: left;
}

.text-center {
    text-align: center;
}

.text-right {
    text-align: right;
}

/* Text Style */
.text-bold {
    font-weight: bold;
}

.text-italic {
    font-style: italic;
}

.text-underline {
    text-decoration: underline;
}

/* Font Sizes */
.fs-1 {
    font-size: 2rem;
}

.fs-2 {
    font-size: 1.5rem;
}

.fs-3 {
    font-size: 1.25rem;
}

.fs-4 {
    font-size: 1rem;
}

.fs-5 {
    font-size: 0.875rem;
}

/* Display */
.d-block {
    display: block;
}

.d-inline-block {
    display: inline-block;
}

.d-none {
    display: none;
}

.d-inline {
    display: inline;
}