@import '../../styles/customMediaQueries.css';

.root {
  margin: 0;
  padding: 0;
  border: none;
}

.list {
  margin: 0;
  columns: 3;
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
  margin: 0;
}

.twoColumns {
  @media (--viewportMedium) {
    flex: 1 0 calc(50% - 10px); /* Makes each column take up a third of the width */
  }
}

.item {
  padding: 4px 0 2px 0;

  flex: 1 0 calc(33.333% - 10px); /* Makes each column take up a third of the width */
  margin: 5px; /* Adds space between items */
  box-sizing: border-box; /* Ensures padding and border are included in the width */
  /* Fix broken multi-column layout in Chrome */
  page-break-inside: avoid;

  @media (--viewportMedium) {
    padding: 6px 0 2px 0;
  }
}
